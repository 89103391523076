<template>
  <Page isHeader :isBack="!showTipMessage">
    <div class="bind-form">
      <van-form label-width="2.2rem" ref="changeForm">
        <div class="lk-p-12 lk-border-bottom">
          <div class="lk-font-md lk-text-bold">验证当前账号的手机号：</div>
          <div class="lk-font-sm font-3 lk-m-t-10">
            需要验证您的手机号进行下一步操作
          </div>
        </div>
        <van-field label="手机号">
          <template slot="input">
            <!-- <phone :phone="$route.query.phone || cardInfo.phone" /> -->
            <phone :phone="cardInfo.phone" />
          </template>
        </van-field>
        <van-field
          v-model="changeForm.oldPhoneCode"
          :rules="[{ required: true, message: '请输入验证码' }]"
          name="验证码"
          type="number"
          clearable
          border
          label="验证码"
          maxlength="6"
          placeholder="请输入验证码"
          autocomplete="off"
        >
          <template #button>
            <MsgCode
              :phone="cardInfo.phone"
              isLine
              type="MEMBER_CARD_CHANGE_PHONE_OLD"
            />
          </template>
        </van-field>
        <div class="lk-p-12 lk-p-t-24 lk-border-bottom lk-font-md lk-text-bold">
          您可关联的新手机号：
        </div>
        <van-field
          v-model="changeForm.newPhone"
          :rules="[
            { required: true, message: '请输入更换手机号' },
            { pattern: patternPhone, message: '手机号格式错误' },
          ]"
          name="手机号"
          type="number"
          clearable
          maxlength="11"
          label="手机号"
          placeholder="更换手机号"
          autocomplete="off"
        />
        <van-field
          v-model="changeForm.newPhoneCode"
          :rules="[{ required: true, message: '请输入验证码' }]"
          name="验证码"
          type="number"
          maxlength="6"
          clearable
          label="验证码"
          placeholder="请输入验证码"
          autocomplete="off"
        >
          <template #button>
            <MsgCode
              :phone="changeForm.newPhone"
              isLine
              type="MEMBER_CARD_CHANGE_PHONE_NEW"
            />
          </template>
        </van-field>
      </van-form>
    </div>
    <div class="content">
      <van-button
        class="lk-m-t-40 font-1"
        color="#F8B500"
        block
        round
        @click="handleClickChange"
        >更换</van-button
      >
    </div>
    <template>
      <Dialog
        :visible.sync="showOperationDialog"
        title="不能换绑"
        :content="tipMessage"
        confirmButtonText="前往修改"
        @confirm="goEditPassword"
      />
    </template>

    <Dialog
      :visible.sync="showTipMessage"
      theme="round-button"
      :content="dialog.message"
      :confirmButtonText="dialog.confirmButtonText"
      :showCancelButton="false"
      @confirm="closeIKnowDialog"
    />
  </Page>
</template>

<script>
import MsgCode from '@/components/Msgcode.vue';
import Phone from '@/components/Phone.vue';
import { updateCardPhoneApi } from '@/api/bind';
import { mapState, mapActions } from 'vuex';
export default {
  name: 'ChangePhone',
  components: {
    MsgCode,
    Phone,
  },
  data() {
    return {
      updateCardPhoneApi,
      isResetToLogin: false,
      showOperationDialog: false,
      tipMessage: '',
      patternPhone: /^1[3456789]\d{9}$/,
      changeForm: {
        oldPhoneCode: '',
        newPhone: '',
        newPhoneCode: '',
      },
      showTipMessage: false,
      dialog: {
        title: '',
        message: '',
      },
      confirmHandler: this.changeBindPhone,
    };
  },
  computed: {
    ...mapState('entery', ['memberInfo']),
    ...mapState('card', ['cardInfo']),
  },
  beforeRouteEnter(to, from, next) {
    // ...
    next((vm) => {
      vm.changeForm = {
        oldPhoneCode: '',
        newPhone: '',
        newPhoneCode: '',
      };
    });
  },

  methods: {
    ...mapActions('entery', ['setMemberInfo']),
    ...mapActions('app', ['resetToLogin']),

    async handleClickChange() {
      this.$refs.changeForm
        .validate()
        .then(async () => {
          this.$dialog
            .confirm({
              title: '确认换绑？',
              message: '本手机号将无法登录旧卡，请慎重操作',
              confirmButtonColor: '#F8B500',
            })
            .then(() => {
              this.changeBindPhone();
            })
            .catch(() => {});
        })
        .catch(() => {
          this.$toast('信息有误，请检查');
        });
    },
    async changeBindPhone() {
      let that = this;
      try {
        const { code, content } = await that.updateCardPhoneApi({
          ...that.changeForm,
          cardNo: this.cardInfo.cardNo,
          serialNo: this.cardInfo.serialNo,
          memberNo: that.memberInfo.memberNo,
        });
        if (code === 200) {
          console.log(content);

          this.showTipMessage = true;
          this.isResetToLogin = true;
          this.dialog.message = '换绑成功，请用绑定手机号进行登录';
          this.dialog.confirmButtonText = '确定';
        }
      } catch (error) {
        if (error.message === '请先修改密码再换绑') {
          this.showOperationDialog = true;
          this.tipMessage = error.message;
          return false;
        }
        this.showTipMessage = true;
        this.dialog.message = error.message;
      }
    }, // 17779557406; 17770515306
    goEditPassword() {
      this.showOperationDialog = false;
      const timer1 = setTimeout(() => {
        this.$router.replace({
          name: 'Password',
          query: {
            action: '1', // edit
            openState: this.cardInfo.openState,
          },
        });
        clearTimeout(timer1);
      }, 300);
    },

    closeIKnowDialog() {
      this.showTipMessage = false;
      console.log('isResetToLogin', this.isResetToLogin);
      if (!this.isResetToLogin) return false;
      const timer2 = setTimeout(() => {
        this.resetToLogin();
        clearTimeout(timer2);
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.bind-form {
  background-color: #ffffff;
}
</style>
