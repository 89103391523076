import request from '@/request/interceptors';

// 获取openId
export function getWeixinOpenIdApi(data) {
  return request({
    url: 'web/qrcode/thirdAuth/v1/white',
    method: 'post',
    data: {
      thirdPlatform: 1,
      businessSource: 8,
      thirdPlatformSource: 1,
      code: data.code,
      appId: data.appId,
      orgId: data.orgId,
    },
  });
}

// 通过openId获取用户绑定信息
export function getBindByOpenidApi(params) {
  return request({
    url: 'service/marketing/coupon/memberInfo/v1/infoByOpenId',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// 手机验证码登录
// 获取手机验证码
export function bindMemberApi(data) {
  return request({
    url: 'service/marketing/coupon/memberInfo/v1/login',
    method: 'post',
    data,
  });
}
// 获取微信jsapi签名检验
export function weinxinConfigApi(params) {
  return request({
    url: 'web/qrcode/wx/jsapi/v1',
    method: 'get',
    params: {
      orgId: localStorage.getItem('ORG_ID') || '',
      businessSource: 8,
      ...params,
    },
  });
}
