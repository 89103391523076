import request from '@/request/interceptors';

// 绑定手机号
export function cardBindApi(data) {
  return request({
    url: 'service/marketing/memberCard/h5/v1/cardBind',
    method: 'post',
    data: {
      ...data,
    },
  });
}

// 更新储值卡绑定的手机号
export function updateCardPhoneApi(data) {
  return request({
    url: 'service/marketing/memberCard/h5/v1/changeCardPhone',
    method: 'post',
    data: {
      ...data,
    },
  });
}
