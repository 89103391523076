var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Page',{attrs:{"isHeader":"","bg":"#ffffff"}},[_c('div',{staticClass:"receive-page"},[_c('div',{staticClass:"content card-wrap lk-p-t-12"},[_c('div',{staticClass:"card-box content lk-relative",style:({
          backgroundImage:
            `url(${_vm.merchantCardInfo.coverUrl})` ||
            'url(' + _vm.$baseImg + 'photo/h5C/card/card_default2.png' + ')',
        })},[_c('div',{staticClass:"card-info lk-flex lk-m-t-12"},[_c('van-image',{attrs:{"round":"","width":"36","height":"36","src":_vm.merchantCardInfo.logoUrl ||
              _vm.$baseImg + 'photo/h5C/card/card_logo.png'}}),_c('span',{staticClass:"info lk-font-16 van-ellipsis"},[_vm._v(" "+_vm._s(_vm.merchantCardInfo.title || '未设置')+" ")])],1)])]),_c('div',{staticClass:"receive-content"},[_c('van-form',{ref:"form",attrs:{"label-width":"2rem"}},[_c('van-field',{attrs:{"rules":[{ required: true, message: '请输入姓名' }],"name":"姓名","autocomplete":"off","type":"text","clearable":"","label":"姓名","maxlength":"20","placeholder":"请输入姓名"},model:{value:(_vm.form.userName),callback:function ($$v) {_vm.$set(_vm.form, "userName", $$v)},expression:"form.userName"}}),_c('van-field',{attrs:{"label":"手机号"}},[_c('template',{slot:"input"},[_c('phone',{attrs:{"phone":_vm.form.phone}})],1)],2),_c('van-field',{attrs:{"rules":[{ required: true, message: '请输入验证码' }],"name":"验证码","type":"number","clearable":"","border":"","label":"验证码","maxlength":"6","placeholder":"请输入验证码","autocomplete":"off"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('MsgCode',{attrs:{"phone":_vm.form.phone,"isLine":"","type":"RECEIVE_MEMBER_CARD"}})]},proxy:true}]),model:{value:(_vm.form.vcode),callback:function ($$v) {_vm.$set(_vm.form, "vcode", $$v)},expression:"form.vcode"}}),_c('van-field',{attrs:{"rules":[
            { required: true, message: '请输入六位密码' },
            {
              validator: _vm.pwdValidator,
              message: '密码过于简单，请重新设置',
              trigger: 'onChange',
            },
            {
              validator: _vm.limitSix,
              message: '请输入六位新密码',
              trigger: 'onChange',
            },
          ],"name":"密码","autocomplete":"off","type":"password","clearable":"","readonly":"","label":"密码","placeholder":"请输入六位密码"},on:{"touchstart":function($event){$event.stopPropagation();return _vm.open(1)}},model:{value:(_vm.form.cardPassword),callback:function ($$v) {_vm.$set(_vm.form, "cardPassword", $$v)},expression:"form.cardPassword"}}),_c('van-number-keyboard',{attrs:{"show":_vm.show,"maxlength":6},on:{"blur":function($event){_vm.show = false}},model:{value:(_vm.form.cardPassword),callback:function ($$v) {_vm.$set(_vm.form, "cardPassword", $$v)},expression:"form.cardPassword"}}),_c('van-field',{attrs:{"rules":[
            { required: true, message: '请输入六位密码' },
            {
              validator: _vm.pwdValidator,
              message: '密码过于简单，请重新设置',
              trigger: 'onChange',
            },
            {
              validator: _vm.limitSix,
              message: '请输入六位新密码',
              trigger: 'onChange',
            },
          ],"name":"密码确认","autocomplete":"off","type":"password","clearable":"","readonly":"","label":"密码确认","placeholder":"请再次输入六位密码"},on:{"touchstart":function($event){$event.stopPropagation();return _vm.open(3)}},model:{value:(_vm.form.conPassword),callback:function ($$v) {_vm.$set(_vm.form, "conPassword", $$v)},expression:"form.conPassword"}}),_c('van-number-keyboard',{attrs:{"show":_vm.repeatShow,"maxlength":6},on:{"blur":function($event){_vm.repeatShow = false}},model:{value:(_vm.form.conPassword),callback:function ($$v) {_vm.$set(_vm.form, "conPassword", $$v)},expression:"form.conPassword"}})],1),_c('van-button',{staticClass:"lk-m-t-40 font-1",attrs:{"color":"#F8B500","block":"","round":""},on:{"click":_vm.handleClickSave}},[_vm._v("立即领取")])],1)]),_c('Dialog',{attrs:{"visible":_vm.showDialog,"showCancelButton":_vm.isShowCancelButton,"content":_vm.errorContent,"cardList":_vm.cardList,"theme":"round-button"},on:{"update:visible":function($event){_vm.showDialog=$event}}}),_c('Dialog',{attrs:{"visible":_vm.showKeepReceiveDialog,"showCancelButton":true,"content":_vm.errorContent,"cardList":_vm.cardList},on:{"update:visible":function($event){_vm.showKeepReceiveDialog=$event},"confirm":_vm.save}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }