<template>
  <span>{{ phoneText }}</span>
</template>

<script>
export default {
  props: {
    phone: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {
    phoneText() {
      if (/^1\d{10}$/.test(this.phone)) {
        return this.phone.replace(/^(\d{3})\d{4}(\d{4})$/, "$1 **** $2");
      } else {
        return this.phone;
      }
    }
  }
};
</script>