<template>
  <Page isHeader bg="#ffffff">
    <div class="receive-page">
      <div class="content card-wrap lk-p-t-12">
        <div
          :style="{
            backgroundImage:
              `url(${merchantCardInfo.coverUrl})` ||
              'url(' + $baseImg + 'photo/h5C/card/card_default2.png' + ')',
          }"
          class="card-box content lk-relative"
        >
          <div class="card-info lk-flex lk-m-t-12">
            <van-image
              round
              width="36"
              height="36"
              :src="
                merchantCardInfo.logoUrl ||
                $baseImg + 'photo/h5C/card/card_logo.png'
              "
            />
            <span class="info lk-font-16 van-ellipsis">
              {{ merchantCardInfo.title || '未设置' }}
            </span>
          </div>
        </div>
      </div>
      <div class="receive-content">
        <van-form label-width="2rem" ref="form">
          <van-field
            v-model="form.userName"
            :rules="[{ required: true, message: '请输入姓名' }]"
            name="姓名"
            autocomplete="off"
            type="text"
            clearable
            label="姓名"
            maxlength="20"
            placeholder="请输入姓名"
          />
          <van-field label="手机号">
            <template slot="input">
              <phone :phone="form.phone" />
            </template>
          </van-field>
          <van-field
            v-model="form.vcode"
            :rules="[{ required: true, message: '请输入验证码' }]"
            name="验证码"
            type="number"
            clearable
            border
            label="验证码"
            maxlength="6"
            placeholder="请输入验证码"
            autocomplete="off"
          >
            <template #button>
              <MsgCode :phone="form.phone" isLine type="RECEIVE_MEMBER_CARD" />
            </template>
          </van-field>
          <van-field
            v-model="form.cardPassword"
            :rules="[
              { required: true, message: '请输入六位密码' },
              {
                validator: pwdValidator,
                message: '密码过于简单，请重新设置',
                trigger: 'onChange',
              },
              {
                validator: limitSix,
                message: '请输入六位新密码',
                trigger: 'onChange',
              },
            ]"
            name="密码"
            autocomplete="off"
            type="password"
            clearable
            readonly
            label="密码"
            placeholder="请输入六位密码"
            @touchstart.stop="open(1)"
          />
          <van-number-keyboard
            v-model="form.cardPassword"
            :show="show"
            :maxlength="6"
            @blur="show = false"
          />
          <van-field
            v-model="form.conPassword"
            :rules="[
              { required: true, message: '请输入六位密码' },
              {
                validator: pwdValidator,
                message: '密码过于简单，请重新设置',
                trigger: 'onChange',
              },
              {
                validator: limitSix,
                message: '请输入六位新密码',
                trigger: 'onChange',
              },
            ]"
            name="密码确认"
            autocomplete="off"
            type="password"
            clearable
            readonly
            label="密码确认"
            placeholder="请再次输入六位密码"
            @touchstart.stop="open(3)"
          />
          <van-number-keyboard
            v-model="form.conPassword"
            :show="repeatShow"
            :maxlength="6"
            @blur="repeatShow = false"
          />
        </van-form>
        <van-button
          class="lk-m-t-40 font-1"
          color="#F8B500"
          block
          round
          @click="handleClickSave"
          >立即领取</van-button
        >
      </div>
    </div>
    <Dialog
      :visible.sync="showDialog"
      :showCancelButton="isShowCancelButton"
      :content="errorContent"
      :cardList="cardList"
      theme="round-button"
    />
    <Dialog
      :visible.sync="showKeepReceiveDialog"
      :showCancelButton="true"
      :content="errorContent"
      :cardList="cardList"
      @confirm="save"
    >
    </Dialog>
  </Page>
</template>
<script>
import MsgCode from '@/components/Msgcode.vue';
import Phone from '@/components/Phone.vue';
import { receiveVipCard, getMerchantCardInfo } from '@/api/card';
import { Toast } from 'vant';
import { mapState } from 'vuex';
import { cipherPassword } from '@/utils/format';
export default {
  name: 'ReceiveCard',
  components: { MsgCode, Phone },
  data() {
    const pwdValidator = (val) => {
      return (
        !/(\d)\1{3}/.test(val) || !/^(?=\d{6}$)1*2*3*4*5*6*7*8*9*0*$/.test(val)
      );
    };
    const limitSix = (val) => {
      return val.length === 6;
    };
    return {
      show: false,
      repeatShow: false,
      showDialog: false,
      isShowCancelButton: false,
      showKeepReceiveDialog: false,
      patternPhone: /^1[3456789]\d{9}$/,
      form: {
        userName: '',
        phone: '',
        vcode: '',
        cardPassword: '',
        conPassword: '',
      },
      phone: '',
      errorContent: '',
      errorTheme: '',
      handleConfirm: null,
      cardList: [],
      pwdValidator,
      limitSix,
      cardNo: '',
      serialNo: '',
      cardId: '',
      merchantCardInfo: {
        title: '',
        coverUrl: '',
        logoUrl: '',
      },
    };
  },
  computed: {
    ...mapState('card', ['cardInfo']),
    ...mapState('entery', ['memberInfo']),
  },
  beforeRouteEnter(to, from, next) {
    // ...
    next((vm) => {
      vm.form = {
        userName: '',
        phone: '',
        vcode: '',
        cardPassword: '',
        conPassword: '',
      };
      vm.form.phone = String(vm.memberInfo.phone);
      if (vm.$route.query.cardId) {
        vm.cardId = vm.$route.query.cardId;
      } else {
        vm.cardId = vm.cardInfo.cardId || localStorage.getItem('CARD_ID');
      }
      vm.getMerchantCardInfo();
    });
  },
  mounted() {},
  methods: {
    async getMerchantCardInfo() {
      const { content } = await getMerchantCardInfo({ cardId: this.cardId });
      this.merchantCardInfo.logoUrl = content.logoUrl;
      this.merchantCardInfo.coverUrl = content.coverUrl;
      this.merchantCardInfo.title = content.title;
    },
    async handleClickSave() {
      const TOAST = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let params = {
        ...this.form,
        orgId: localStorage.getItem('ORG_ID') || '',
        cardId: this.cardInfo.cardId || '1000121178',
        cardPassword: cipherPassword(this.form.phone, this.form.cardPassword),
        conPassword: cipherPassword(this.form.phone, this.form.conPassword),
      };
      this.$refs.form.validate().then(async () => {
        const { content } = await receiveVipCard(params);
        TOAST.clear();
        if (content.success) {
          this.cardNo = content.cardNo;
          this.serialNo = content.serialNo;
          this.$router.push({
            name: 'User',
            query: {
              cardNo: this.cardNo,
              serialNo: this.serialNo,
            },
          });
        } else {
          console.log('---->弹窗信息', content);
          this.errorContent = content.errorMsg;
          this.cardList = content.memberNos;
          if (
            content.memberNos &&
            content.memberNos.length < 3 &&
            content.memberNos.length >= 1
          ) {
            console.log(111);
            this.showKeepReceiveDialog = true;
          } else {
            console.log(222);
            this.showDialog = true;
            this.isShowCancelButton = false;
          }
        }
      });
    },
    async save() {
      let params = {
        ...this.form,
        orgId: localStorage.getItem('ORG_ID') || '',
        cardId: this.cardInfo.cardId || '1000121178',
        cardPassword: cipherPassword(this.form.phone, this.form.cardPassword),
        conPassword: cipherPassword(this.form.phone, this.form.conPassword),
      };
      const { content } = await receiveVipCard({ ...params, conReceive: 1 });
      if (content.success) {
        this.cardNo = content.cardNo;
        this.serialNo = content.serialNo;
        this.$router.push({
          name: 'User',
          query: {
            cardNo: this.cardNo,
            serialNo: this.serialNo,
          },
        });
      } else {
        this.errorContent = content.errorMsg;
        this.showDialog = true;
        this.isShowCancelButton = false;
      }
    },
    open(type) {
      if (type == 1) {
        this.show = true;
        this.repeatShow = false;
      }
      if (type == 3) {
        this.show = false;
        this.repeatShow = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.receive-page {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}
.card-wrap {
  background-color: #f5f7fa;
}
.card-box {
  width: 100%;
  background-size: 100% 175%;
  height: 120px;
  border-radius: 8px 8px 0 0;
  box-sizing: border-box;
  overflow: hidden;
}
.card-info {
  margin: 12px 0;
  padding: 12px 0;
  .info {
    flex: 1;
    display: inline-block;
    margin-left: 12px;
    font-weight: 500;
    color: #fff;
  }
}

.receive-content {
  flex-grow: 1;
  border-radius: 12px;
  padding: 0 12px;
}
</style>
