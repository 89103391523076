<template>
  <span
    class="code-btn"
    :class="{ 'left-line': isLine }"
    @click="sendMsgCode"
    >{{ codeTime }}</span
  >
</template>

<script>
import { getVCodeWhiteApi } from '@/api/app';
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    phone: {
      type: [String, Number],
      default: '',
    },
    params: {
      type: Object,
      default: () => {},
    },
    isLine: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      codeTime: '发送验证码',
      timer: null,
    };
  },
  deactivated() {
    this.resetTimer();
  },
  beforeDestroy() {
    this.resetTimer();
  },

  methods: {
    resetTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
        this.codeTime = '发送验证码';
      }
    },
    timerRunning() {
      if (this.timer) return;
      let time = 60;
      this.timer = setInterval(() => {
        time--;
        this.codeTime = `${time} s`;
        if (time <= 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.codeTime = '发送验证码';
        }
      }, 1000);
    },
    async sendMsgCode() {
      if (this.timer) return;
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$toast('请使用正确的手机号');
        return false;
      }
      let body = {
        phone: this.phone,
        templateType: this.type,
        params: this.params,
        nonceStr: Math.random().toString(),
        signName: '大黄鹅',
      };
      try {
        const { code } = await getVCodeWhiteApi({
          ...body,
        });
        if (code === 200) {
          this.$emit('success', true);
          this.timerRunning();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.code-btn {
  color: #f8b500;
  font-size: 16px;
  display: inline-block;
  max-width: 100px;
  min-width: 30px;
}
.left-line {
  padding-left: 12px;
  border-left: 1px solid #d4d7d9;
}
</style>
